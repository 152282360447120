<template>
  <div class="vx-row">
    <div class="vx-col md:w-1/1 w-full">
      <vx-card>
        <span>
          <h4 class="mb-4">Edit Role Menu</h4>
        </span>
        <br />
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Role</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <vs-input class="w-full" v-model="role" readonly disabled />
          </div>
        </div>
        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-1/5 w-full">
            <span>Menu</span>
          </div>
          <div class="vx-col sm:w-4/5 w-full">
            <ul>
              <li v-for="item in data" :key="item.id" class="mb-1">
                <vs-checkbox v-model="item.selected">
                  {{ item.display_name }}
                </vs-checkbox>

                <!-- Submenus -->
                <div style="margin-left: 5%">
                  <ul>
                    <li v-for="submenu in item.submenus" :key="submenu.id" class="mb-1">
                      <vs-checkbox v-model="submenu.selected">
                        {{ submenu.display_name }}
                      </vs-checkbox>

                      <!-- Permissions -->
                      <div style="margin-left: 5%">
                        <ul>
                          <li
                            v-for="permission in submenu.permissions"
                            :key="permission.id"
                            class="mt-2"
                          >
                            <vs-checkbox v-model="permission.selected">
                              {{ permission.display_name }}
                            </vs-checkbox>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="vx-row mb-6 ml-4 mr-4">
          <div class="vx-col sm:w-4/5 w-full ml-auto">
            <vs-button v-on:click="handleSubmit" class="mr-3 mb-2">Update</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: "",
      data: [],
    };
  },
  methods: {
    getMenu() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/setting/menu-v1", { params: { source: "SFA" } })
        .then((resp) => {
          if (resp.code === 200) {
            this.data = resp.data.records
              .sort((a, b) => a.id - b.id)
              .map((menu) => ({
                ...menu,
                selected: false,
                submenus: menu.submenus.map((submenu) => ({
                  ...submenu,
                  selected: false,
                  permissions: submenu.permission.map((permission) => ({
                    ...permission,
                    selected: false,
                  })),
                })),
              }));
          }
          return this.getData();
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/setting/role-menu-v1/" + this.$route.params.id)
        .then((resp) => {
          if (resp.code === 200) {
            this.role = resp.data.display_name;
            const modules = resp.data.modules;

            this.data.forEach((menu) => {
              const matchedModule = modules.find((mod) => mod.id === menu.id);
              if (matchedModule) {
                menu.selected = true;
                menu.submenus.forEach((submenu) => {
                  const matchedSubmenu = matchedModule.submenus.find(
                    (sm) => sm.id === submenu.id
                  );
                  if (matchedSubmenu) {
                    submenu.selected = true;
                    submenu.permissions.forEach((permission) => {
                      const matchedPermission = matchedSubmenu.permissions.find(
                        (perm) => perm.id === permission.id
                      );
                      if (matchedPermission) {
                        permission.selected = true;
                      }
                    });
                  }
                });
              }
            });
            this.$vs.loading.close();
          }
        });
    },
    handleSubmit() {
      const selectedData = this.data.flatMap((menu) => {
        const selectedSubmenus = menu.submenus
          .filter((submenu) => submenu.selected)
          .map((submenu) => ({
            submenu_id: submenu.id,
            selected_permissions: submenu.permissions
              .filter((permission) => permission.selected)
              .map((perm) => perm.id),
          }))
          .filter(submenu => submenu.selected_permissions.length > 0);


        if (menu.selected) {
          return [
            {
              menu_id: menu.id,
              selected_submenus: selectedSubmenus,
            },
          ];
        }
        return [];
      });

      this.putData(selectedData);
    },
    putData(selectedData) {
      this.$vs.loading();
      this.$http
        .put(
          "/api/sfa/v1/setting/role-menu-v1/" + this.$route.params.id,
          selectedData
        )
        .then((resp) => {
          if (resp.code === 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Role Menu Updated",
              position: "top-right",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleClose() {
      this.$router.push({ name: "role-menu" });
    },
  },
  mounted() {
    this.getMenu();
  },
};
</script>
